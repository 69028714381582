
export default {
  name: 'landing-list-item',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
