import { render, staticRenderFns } from "./index.vue?vue&type=template&id=35fd36a8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=35fd36a8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules_dev/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35fd36a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSection: require('/vercel/path0/src/components/base/Section.vue').default,LandingStep: require('/vercel/path0/src/components/landing/Step.vue').default,LandingListItem: require('/vercel/path0/src/components/landing/ListItem.vue').default,BaseAccordion: require('/vercel/path0/src/components/base/Accordion.vue').default,LandingContacts: require('/vercel/path0/src/components/landing/Contacts.vue').default,LandingContactForm: require('/vercel/path0/src/components/landing/ContactForm.vue').default})
