
export default {
  name: 'landing-contacts',
  data() {
    return {
      contacts: [
        {
          label: '+7 (913) 179-40-86',
          href: 'tel:79131794086',
          icon: 'phone.png',
        },
        {
          label: 'Карамзина, 24',
          href: 'https://2gis.ru/krasnoyarsk/search/абрикосики/firm/70000001038146486/92.850969%2C55.989388',
          icon: 'home.png',
        },
        {
          label: 'abrikosiki.krsk',
          href: 'https://instagram.com/abrikosiki.krsk',
          icon: 'ig.png',
        },
        {
          label: 'abrikosiki.krsk',
          href: 'https://vk.com/abrikosiki.krsk',
          icon: 'vk.png',
        },
      ],
    };
  },
};
