
export default {
  name: 'contact-form',
  data() {
    return {
      phone: '',
      comment: '',
      isLoading: false,
    };
  },
  methods: {
    sendMail() {
      this.isLoading = true;
      this.$mail.send({
        from: 'Абрикосики',
        subject: 'Абрикосики',
        text: `Номер телефона: ${this.phone}. Комментарий: ${this.comment}`,
      }).then(() => {
        this.phone = '';
        this.comment = '';
        this.isLoading = false;
      });
    },
  },
};
