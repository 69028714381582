
export default {
  name: 'landing-step',
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
};
